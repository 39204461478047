import * as React from "react";
import {IMusicListDetailProps} from "./interfaces";



export class MusicListDetail extends React.Component<IMusicListDetailProps, {}> {
    constructor(props: IMusicListDetailProps) {
        super(props);
    }

    public getYear(): number {
        return new Date(this.props.date).getFullYear();
    }
}
