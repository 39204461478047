import * as React from 'react';
import {Route, Switch} from 'react-router';
import {PartialBioFull} from "./PartialBioFull";
import {PartialBioMultiLine} from "./PartialBioMultiLine";
import {PartialBioOneLiner} from "./PartialBioOneLiner";


export class PartialBio extends React.Component<{}> {
    constructor(props: any) {
        super(props);
    }

    public render() {
        return (<div>
            <div className="place-holder col-lg-4 col-md-4 col-sm-3 hidden-xs-down" />
            <div className="center-locator col-lg-4 col-md-4 col-sm-6 col-xs-12">
                <br className="clear" />
                <h1 className="name">Osbert Ngok</h1>
                <Switch>
                    <Route exact={true} path="/bio" component={PartialBioOneLiner} />
                    <Route path="/bio/multiline" component={PartialBioMultiLine} />
                    <Route path="/bio/full" component={PartialBioFull} />
                </Switch>
            </div>
            <div className="place-holder col-lg-4 col-md-4 col-sm-3 hidden-xs-down" />
        </div>);
    }
}
