import * as React from "react";
import {IMusicListDetailProps} from "./interfaces";
import {MusicListDetail} from "./MusicListDetail";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as ccImg from '../../img/common/by-nc-sa.png';

interface IMusicListState {
    children: any[]
}

interface IMusicListJSON {
    data: IMusicListDetailProps[]
}

export class MusicList extends React.Component<{}, IMusicListState> {
    constructor(props: any) {
        super(props);

        this.state = {
            children: []
        };

        this.getMusicListAsync();

    }


    public async getMusicListAsync() {
        try {
            // Get json
            const response: Response = await fetch('/json/music.json', {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'GET'
            });
            const text = await response.text();
            console.log(text);
            const data: IMusicListJSON = JSON.parse(text);
            this.setState({
                children: data.data.map((song: IMusicListDetailProps) => new MusicListDetail(song))
            });
        } catch (e) {
            console.log(e);
            this.setState({
                children: []
            });
        }
    }

    public render() {
        return (
            <div>
                <a rel="license" href="https://creativecommons.org/licenses/by-nc-sa/3.0/">
                    <img alt="Creative Commons License" className="border-none" src={ccImg} />
                </a>
                {
                    this.state.children.filter((detail) => detail.props.favourate).map((detail) =>
                        (
                            <div className="music-list-detail" key={detail.props.id}>
                                {console.log(detail)}
                                <div className="music-list-detail-title">
                                    <span>{detail.props.title}</span>&nbsp;{
                                    detail.props.score &&
                                    <a href={detail.props.score}>
                                        <FontAwesomeIcon icon={["fas", "book"]} />
                                    </a>
                                }
                                    <span>(<span>{detail.getYear().toString()}</span>)</span>
                                </div>
                                <audio className="music-list-detail-audio" controls={true} preload="metadata">
                                    <source src={detail.props.mp3} type="audio/mpeg" />
                                    Your browser does not support the audio element.
                                </audio>
                            </div>)
                    )
                }
            </div>
        );
    }
}
