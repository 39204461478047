import * as React from 'react';
import {Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class PartialHome extends React.Component<{},{}> {
    constructor(props: any) {
        super(props);
    }

    public render() {
        return (<div>
            <div className="place-holder col-lg-4 col-md-4 col-sm-3 col-xs-2" />
            <div className="center-locator col-lg-4 col-md-4 col-sm-6 col-xs-8">
                <br className="clear" />
                <h1 className="name">Osbert Ngok</h1>
                <ul className="links homepage-links">
                    <li>
                        <Link to="/bio" title="One-liner Biography">Bio</Link>
                    </li>
                    <li>
                        <a href="/blog" title="Blog">Blog</a>
                    </li>
                    <li>
                        <Link to="/music" title="Music">Music</Link>
                    </li>
                    <li>
                    <a>About</a>
                    </li>
                </ul>
                <div className="social">
                    <ul className="links social-links">
                        <li>
                            <a href="https://github.com/osbertngok/" title="Github Page">
                                <FontAwesomeIcon icon={["fab", "github"]}/>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/osbertngok/" title="Facebook Page">
                                <FontAwesomeIcon icon={["fab", "facebook"]}/>
                            </a>
                        </li>
                        <li>
                            <a href="http://weibo.com/osbertngok/" title="Weibo Page">
                                <FontAwesomeIcon icon={["fab", "weibo"]}/>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="place-holder col-lg-4 col-md-4 col-sm-3 col-xs-2" />
        </div>);
    }
}
