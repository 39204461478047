import * as React from 'react';
import {Route, Switch} from 'react-router';
import {HashRouter} from 'react-router-dom';

import 'bootstrap-sass/assets/stylesheets/_bootstrap.scss';
import '../css/common/fonts.scss';
import '../css/common/style.scss';
import '../css/public/style.scss';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebook, faGithub, faWeibo } from '@fortawesome/free-brands-svg-icons';
import { faBatteryFull, faBatteryHalf, faBatteryQuarter, faBook, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
library.add(faChevronLeft, faBatteryQuarter, faBatteryHalf, faBatteryFull, faBook, faGithub, faFacebook, faWeibo);

import {PartialBio} from "./bio/PartialBio";
import {PartialHome} from "./home/PartialHome";
import {PartialMusic} from "./music/PartialMusic";


type SectionType = 'Home' | 'Bio' | 'Music';

interface IAppState {
    section: SectionType
}

const AppSections = {
    'Bio': PartialBio,
    'Home': PartialHome,
    'Music': PartialMusic
};

export class OsbertNgokWebsiteApp extends React.Component<{}, IAppState> {
    constructor(props: any) {
        super(props);
        this.state = {
            section: 'Home'
        };
    }

    public changeSection(section: SectionType): void {
        this.setState(AppSections[section] ? {
            section
        } : {
            section: 'Home'
        })
    }

    public getSubComponent() {
        return AppSections[this.state.section] || PartialHome;
    }

    public render() {
        return (
            <div className="container ui-view-container">
                <HashRouter>
                    <Switch>
                        <Route exact={true} path="/" component={PartialHome} />
                        <Route path="/bio" component={PartialBio} />
                        <Route path="/music" component={PartialMusic} />
                    </Switch>
                </HashRouter>
            </div>);
    }
}

