import * as React from 'react';
import {MusicList} from "./MusicList"

export class PartialMusic extends React.Component<{}> {
    constructor(props: any) {
        super(props);
    }

    public render() {
        return <div>
            <div className="place-holder col-lg-4 col-md-4 col-sm-3 hidden-xs-down" />
            <div className="center-locator col-lg-4 col-md-4 col-sm-6 col-xs-12">
            <br className="clear" />
            <h1>My Compositions</h1>
            <MusicList />
            </div>
            <div className="place-holder col-lg-4 col-md-4 col-sm-3 hidden-xs-down" />
        </div>;
    }
}
