import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as React from 'react';
import {Link} from 'react-router-dom';


export class PartialBioOneLiner extends React.Component<{}> {
    constructor(props: any) {
        super(props);
    }

    public render() {
        return (
            <div>
                <p>
                    is an automation advocate, web developer, productivity and gamification enthusiast, amateur composer.
                </p>
                <div className="bio-navigation">
                    <ul className="links bio-links row">
                        <li className="col-xs-3 text-align-left">
                            <Link to="/" title="Home Page">
                                <FontAwesomeIcon icon={["fas", "chevron-left"]} />
                            </Link>
                        </li>
                        <li className="col-xs-6">&nbsp;</li>
                        <li className="col-xs-3 text-align-right">
                            <Link to="/bio/multiline" title="Multiple Lines Biography">
                                <FontAwesomeIcon icon={["fas", "battery-half"]} />
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}
