import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as React from 'react';
import {Link} from 'react-router-dom';


export class PartialBioFull extends React.Component<{}> {
    constructor(props: any) {
        super(props);
    }

    public render () {
        return (
            <div>
                <p>
                    is a software system integration engineer at <a href="https://mujin.co.jp/">Mujin</a> focusing on integration toolset and reporting. He specializes in designing integration tools and reporting / monitoring systems. Previously he worked for <a href="https://implus.co/">Implus</a>, <a href="http://messerfs.com/">Messer Financial Software</a>, <a href="http://www.deloitte.com/">Deloitte Consulting</a>, and <a href="http://www.ubs.com/">UBS</a>.
                </p>
                <p>
                    In his personal life he is also interested in productivity and long-term goal management. Since 2007 he has written a dozen of Personal Information Management (PIM) systems to assist users to review and improve their life, so they can get closer to who they want themselves to be.
                </p>
                <p>
                    He is an amateur pianist and guitarist. In addition to do actual composition by himself, he also delegates the job to programs. In 2011 he partnered with <a href="http://physbam.stanford.edu/~yuey/">Yu Yue</a> to develop an algorithm to <a href="/blog/2011/05/01/automated-music-composition/">compose fugues</a>.
                </p>
                <div className="bio-navigation">
                    <ul className="links bio-links row">
                        <li className="col-xs-3 text-align-left">
                            <Link to="/bio/multiline" title="Multiple Lines Biography">
                                <FontAwesomeIcon icon={["fas", "battery-half"]} />
                            </Link>
                        </li>
                        <li className="col-xs-6">&nbsp;</li>
                        <li className="col-xs-3 text-align-right">
                            &nbsp;
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}
