import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {OsbertNgokWebsiteApp} from './containers/App';


ReactDOM.render(
  <OsbertNgokWebsiteApp />,
  document.getElementById('app') as HTMLElement
);

